import React from 'react';
import SITELINKS from 'constants/sitelinks';
import EditRecordButton from 'components/editRecordButton';
import ViewPackageButton from 'components/viewPackagebutton';
import DownloadLabelButton from 'components/downloadLabelButton';
import { useBuildPackage } from 'util/Hooks';
import { useSelector } from 'react-redux';
import {
  itemsSelector,
  pageableSelector,
} from 'redux/packagesByStore/selectors';
import SizeIndicator from 'components/sizeIndicator';

const downloadLabelColumn = {
  render: (_, record) => (
    <DownloadLabelButton
      packageId={record.key}
      externalCode={record.externalCode}
    />
  ),
};

const externalCodeColumn = {
  title: 'Externo',
  dataIndex: 'externalCode',
  width: 130,
  render: (text) => <span style={{ wordBreak: 'break-word' }}>{text}</span>,
};

const destinationPersonNameColumn = {
  title: 'Nombre destinatario',
  dataIndex: 'destinationPersonName',
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTermDescription',
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'statusDescription',
};

const sizeColumn = {
  title: 'Tamaño',
  dataIndex: 'packageSizeDescription',
  align: 'center',
  render: (text) => <SizeIndicator text={text[0]} />,
};

const editColumn = {
  align: 'center',
  render: (_, record) => (
    <EditRecordButton record={record} link={`${SITELINKS.packages.list}/id`} />
  ),
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packageId={record.key} />,
};

const columns = [
  externalCodeColumn,
  destinationPersonNameColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  sizeColumn,
  arrivedAtPaqueryPointDateColumn,
  statusColumn,
];

const actionColumns = [viewPackageColumn, editColumn];

const colsLG = [downloadLabelColumn, ...columns, ...actionColumns];

const colsMD = [
  downloadLabelColumn,
  externalCodeColumn,
  statusColumn,
  ...actionColumns,
];

const colsSM = [downloadLabelColumn, externalCodeColumn, ...actionColumns];

export default function useTableDataNormal() {
  const items = useSelector(itemsSelector);
  const pageable = useSelector(pageableSelector);
  const packages = useBuildPackage(items);
  return {
    packages,
    pageable,
    colsSM,
    colsMD,
    colsLG,
    columns,
  };
}
