import React, { useCallback, useState } from 'react';
import { notification } from 'antd';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import { PERMISSIONS } from 'constants/roles';
import API from 'constants/api';
import { ExportDateFormat } from 'constants/dateFormats';
import rest from 'util/Api';
import dayjs from 'dayjs';
import PaqueryTable, { TableHeader } from '@paquery-team/lib-table';
import CsvDownloader from 'util/CsvDownloader';
import DeleteModal from 'components/deleteModal';
import { useDispatch, useSelector } from 'react-redux';
import useMarketplaces from 'redux/marketplaces';
import { actions } from 'redux/marketplaces/slice';
import { authProfile } from 'redux/auth/selectors';
import {
  marketplacesLoadedSelector,
  marketplacesItemsSelector,
  marketplacesFilteredItemsSelector,
} from 'redux/marketplaces/selectors';
import DeleteRecordButton from 'components/deleteRecordButton';
import EditRecordButton from 'components/editRecordButton';

const MarketplaceList = () => {
  useMarketplaces({ initialize: true });
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const profile = useSelector(authProfile);
  const loaded = useSelector(marketplacesLoadedSelector);
  const fullItems = useSelector(marketplacesItemsSelector);
  const { items, pageable } = useSelector(marketplacesFilteredItemsSelector);

  const deleteAction = (marketId, marketName) => {
    const deleteRequest = async () => {
      let response;
      try {
        response = await rest.del(`${API.marketplaces.delete}/${marketId}`);
        if (response.status !== 400) {
          dispatch(actions.refreshPage());
          dispatch(actions.initial());
        }
      } catch (error) {
        // nothing to do
      }
      return response;
    };
    DeleteModal({
      title: `¿Estás seguro de que deseas eliminar a ${marketName}?`,
      onConfirmation: deleteRequest,
    });
  };

  const idColumn = {
    title: 'ID',
    dataIndex: 'key',
  };

  const nameColumn = {
    title: 'Nombre',
    dataIndex: 'name',
  };

  const detailColumn = {
    title: 'Descripción',
    dataIndex: 'detail',
  };

  const editColumn = {
    title: 'Editar',
    align: 'center',
    render: (_, record) => (
      <EditRecordButton link="/marketplaces" record={record} />
    ),
  };

  const deleteColumn = {
    title: 'Eliminar',
    align: 'center',
    render: (_, record) => (
      <DeleteRecordButton
        onClick={() => deleteAction(record.key, record.name)}
      />
    ),
  };

  const dataColumns = [idColumn, nameColumn, detailColumn];

  const fullSizeColumns = [...dataColumns, editColumn, deleteColumn];

  const columnsSmallDevice = [idColumn, nameColumn];

  const getCsv = async () => {
    setDisabled(true);
    try {
      const csv = fullItems;
      const fields = [
        { label: 'ID', value: 'id' },
        { label: 'Nombre', value: 'name' },
        { label: 'Descripción', value: 'detail' },
        { label: 'Operadores Logísticos', value: 'logisticOperators' },
      ];
      const filename = `listado-marketplaces-${dayjs()
        .tz()
        .format(ExportDateFormat)}.csv`;
      CsvDownloader(csv, fields, filename);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: 'Ha ocurrido un error al traer los marketplaces',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };

  let filteredMarkets;
  if (items) {
    filteredMarkets = items.map((market) => ({
      key: market.id,
      name: market.name,
      detail: market.detail,
      logisticOperators: '',
    }));
  }

  const searchCallback = useCallback(
    (searchText) => {
      dispatch(actions.updateSearch(searchText));
    },
    [dispatch],
  );
  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Nombre',
    allowEmptySearch: true,
  };
  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePageable(page));
    },
    [dispatch],
  );
  const canAlterMarketplaces = profile.userRoleActions.some(
    (role) => role === PERMISSIONS.AlterMarketplace,
  );
  return (
    <PaqueryTable
      loading={!loaded}
      header={
        canAlterMarketplaces
          ? {
              title: 'Marketplaces',
              refresh: () => dispatch(actions.refreshPage()),
              searcher,
              onExportCsv: { callback: getCsv, disabled },
              primaryButton: <TableHeader.AddButton url="/marketplaces/add" />,
            }
          : {
              title: 'Marketplaces',
              refresh: () => dispatch(actions.refreshPage()),
              searcher,
              onExportCsv: { callback: getCsv, disabled },
            }
      }
      onChangePaginate={updatePaginate}
      dataSource={filteredMarkets}
      paginate={pageable || DEFAULT_PAGINATE}
      dataColumns={dataColumns}
      colsForMediumDevice={fullSizeColumns}
      colsForSmallDevice={columnsSmallDevice}
      colsForLargeDevice={fullSizeColumns}
    />
  );
};

export default MarketplaceList;
