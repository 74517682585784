import React from 'react';
import { theme } from 'antd';

export default function SizeIndicator({ text }: any) {
  const { token } = theme.useToken();
  return (
    <span
      style={{
        background: token.colorPrimary,
        padding: 3,
        color: token.colorBgBase,
        borderRadius: '10px',
        fontWeight: 'bold',
      }}
    >
      {text}
    </span>
  );
}
