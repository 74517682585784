import { useQuery } from '@tanstack/react-query';
import api from 'constants/api';
import dayjs from 'dayjs';
import Api from 'util/Api';

interface Alert {
  message?: string;
  startsFrom?: string;
  endsBy?: string;
  key?: string;
}

const getAlert = async () => {
  const now = dayjs();
  const response = await Api.apiAxios.get<Alert>(api.alert);

  if (!Api.isSuccessResponse(response)) {
    throw new Error('No se han podido obtener la alerta.');
  }

  const alert = response.data;
  if (
    now.isAfter(dayjs(alert.startsFrom).tz()) &&
    now.isBefore(dayjs(alert.endsBy).tz())
  )
    return alert;
  return {};
};

export const useAlert = () => {
  return useQuery<Alert, Error>({
    queryKey: ['alert'],
    queryFn: getAlert,
    retry: 1,
    staleTime: 600000,
    placeholderData: {},
  });
};
