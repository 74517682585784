import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, getIn } from 'formik';
import { Tooltip, Select, theme } from 'antd';
import useStyles from './styles';

const { Option } = Select;

const SelectField = ({
  allowClear,
  label,
  fieldName,
  defaultValue,
  firstDefault,
  placeholder,
  onChange,
  mode,
  onSearch,
  showSearch,
  filterOption,
  children,
  disabled,
  options,
  containerClass,
  containerStyle,
}) => {
  const { values, errors, setFieldValue, setFieldTouched, touched } =
    useFormikContext();
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });

  return (
    <div
      className={containerClass}
      style={{
        textAlign: 'left',
        width: '100%',
        boxSizing: 'border-box',
        ...containerStyle,
      }}
    >
      <span style={{ margin: '5px 0px' }}>{label}</span>
      <Tooltip
        destroyTooltipOnHide
        title={errors[fieldName]}
        open={!!errors[fieldName] && touched[fieldName]}
        color="red"
      >
        <Select
          allowClear={allowClear}
          className={classes.topSelectEffect}
          name={fieldName}
          mode={mode || null}
          showSearch={showSearch}
          filterOption={filterOption}
          disabled={disabled}
          defaultValue={defaultValue}
          defaultActiveFirstOption={firstDefault}
          onSearch={onSearch}
          onBlur={() => setFieldTouched(fieldName)}
          onChange={(value) => {
            setFieldValue(fieldName, value);
            if (onChange) {
              onChange(value);
            }
          }}
          value={getIn(values, fieldName)}
          placeholder={placeholder || label}
          style={{ width: '100%' }}
        >
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
          {children}
        </Select>
      </Tooltip>
    </div>
  );
};

SelectField.propTypes = {
  allowClear: PropTypes.bool,
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  mode: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstDefault: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  containerClass: PropTypes.string,
  containerStyle: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.object),
  filterOption: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  showSearch: PropTypes.bool,
};

SelectField.defaultProps = {
  allowClear: false,
  disabled: false,
  defaultValue: null,
  mode: null,
  onChange: null,
  onSearch: null,
  placeholder: null,
  containerStyle: null,
  containerClass: null,
  options: null,
  showSearch: undefined,
  filterOption: undefined,
  firstDefault: undefined,
};

export default SelectField;
