import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Spin } from 'antd';
import { GoogleMap, Marker, Polygon } from '@react-google-maps/api';
import { Point, Zone } from 'services/ZoneService';
import useStyle from './styles';
import usePolygonColors from './usePolygonColors';

type LatLngLiteral = google.maps.LatLngLiteral;
type LatLng = google.maps.LatLng;

interface MapProps {
  polygons: Zone[];
  initialCenter: LatLngLiteral;
  zoom?: number;
  editablePolygon?: Zone;
  centerPolygon?: Zone;
  isAddZone?: boolean;
  arePolygonsLoading?: boolean;
  onPolygonChange?: (path: Point[]) => void;
}

const mapContainerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '8px',
};

const defaultPolygonOptions = {
  strokeOpacity: 0.7,
  strokeWeight: 3,
  fillOpacity: 0.4,
};

const convertPointToLatLngLiteral = (point: Point): LatLngLiteral => ({
  lat: parseFloat(point.lat),
  lng: parseFloat(point.lng),
});

const getPolygonCenter = (path: LatLngLiteral[]): LatLngLiteral => {
  const bounds = new google.maps.LatLngBounds();
  path.forEach((coord) => bounds.extend(coord));
  return bounds.getCenter().toJSON();
};

const Map: React.FC<MapProps> = ({
  polygons,
  initialCenter,
  zoom = 10,
  editablePolygon = null,
  centerPolygon = null,
  isAddZone = false,
  arePolygonsLoading = false,
  onPolygonChange,
}) => {
  const classes = useStyle();
  const mapRef = useRef<google.maps.Map | null>(null);
  const polygonRefs = useRef<{ [key: number]: google.maps.Polygon }>({});
  const drawingManagerRef = useRef<google.maps.drawing.DrawingManager | null>(
    null,
  );
  const [mapCenter, setMapCenter] = useState(initialCenter);
  const [centeredPolygonCenter, setCenteredPolygonCenter] = useState<{
    lat: number;
    lng: number;
  }>();
  const [mapLoaded, setMapLoaded] = useState(false);
  const [drawingManagerLoaded, setDrawingManagerLoaded] = useState(false);
  const [markersVisible, setMarkersVisible] = useState(true);
  const [currentPolygon, setCurrentPolygon] =
    useState<google.maps.Polygon | null>(null);

  const { getColorForPolygon } = usePolygonColors();

  // Si hay un polygono para editar, lo dibujo.
  useEffect(() => {
    if (
      mapLoaded &&
      drawingManagerLoaded &&
      polygons.length > 0 &&
      editablePolygon &&
      !currentPolygon
    ) {
      addEditablePolygon(editablePolygon);
    }
  }, [mapLoaded, drawingManagerLoaded, polygons, editablePolygon]);

  // Si hay un poligono para centrar, muevo el mapa a ese polygono.
  useEffect(() => {
    if (mapLoaded && centerPolygon) {
      adjustMapBoundsForZone(centerPolygon);
    }
  }, [mapLoaded, centerPolygon]);

  // Inicializa el Drawing Manager
  const initializeDrawingManager = useCallback(() => {
    if (!mapRef.current) return;

    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      polygonOptions: { editable: true },
    });

    drawingManagerRef.current = drawingManager;
    drawingManager.setMap(mapRef.current);
    setDrawingManagerLoaded(true);

    // Si estoy dibujando una zona nueva, le agrego los listeners de edicion una vez completo el poligono
    if (isAddZone) {
      google.maps.event.addListener(
        drawingManager,
        'overlaycomplete',
        (event: google.maps.drawing.OverlayCompleteEvent) => {
          if (event.type === google.maps.drawing.OverlayType.POLYGON) {
            const newPolygon = event.overlay as google.maps.Polygon;
            setPolygonListeners(newPolygon);
          }
        },
      );
    }
  }, [isAddZone, mapRef]);

  // Llamo a onPolygonChange con el path del poligono pasado por parametro
  const handlePolygonChange = useCallback(
    (polygon: google.maps.Polygon) => {
      if (onPolygonChange && polygon) {
        const path = polygon
          .getPath()
          .getArray()
          .map(
            (latLng: LatLng): Point => ({
              lat: latLng.lat().toString(),
              lng: latLng.lng().toString(),
            }),
          );
        if (path.length) onPolygonChange(path);
      }
    },
    [onPolygonChange],
  );

  // Le seteo los listeners de edicion a un poligono
  const setPolygonListeners = useCallback(
    (polygon: google.maps.Polygon) => {
      if (!drawingManagerRef.current) return;
      drawingManagerRef.current.setDrawingMode(null);
      google.maps.event.addListener(polygon.getPath(), 'set_at', () =>
        handlePolygonChange(polygon),
      );
      google.maps.event.addListener(polygon.getPath(), 'insert_at', () =>
        handlePolygonChange(polygon),
      );
      google.maps.event.addListener(polygon.getPath(), 'remove_at', () =>
        handlePolygonChange(polygon),
      );
      setCurrentPolygon(polygon);
      handlePolygonChange(polygon);
    },
    [handlePolygonChange],
  );

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      mapRef.current = map;
      setMapLoaded(true);
      if (isAddZone || editablePolygon) initializeDrawingManager();

      // Listener para mostrar o no los markers segun el zoom
      google.maps.event.addListener(map, 'zoom_changed', () =>
        setMarkersVisible(map.getZoom()! >= 8),
      );
    },
    [editablePolygon, isAddZone, initializeDrawingManager],
  );

  const onUnmount = useCallback(() => {
    mapRef.current = null;
    setMapLoaded(false);
    Object.values(polygonRefs.current).forEach((polygon) => {
      polygon.setMap(null);
    });
    if (drawingManagerRef.current) {
      drawingManagerRef.current.setMap(null);
    }
  }, [drawingManagerRef, polygonRefs]);

  // Centro el mapa para una zona, teniendo en cuenta si es normal o agrupadora
  const adjustMapBoundsForZone = useCallback(
    (zone: Zone) => {
      if (!mapRef.current) return;

      const bounds = new google.maps.LatLngBounds();

      const extendBoundsForPath = (path: Point[]) => {
        path.forEach((point) =>
          bounds.extend(convertPointToLatLngLiteral(point)),
        );
      };

      if (zone.path && zone.path.length > 0) {
        extendBoundsForPath(zone.path);
      }

      if (zone.childrenZones && zone.childrenZones.length > 0) {
        (zone.childrenZones as Zone[]).forEach((childZone: Zone) => {
          if (childZone.path && childZone.path.length > 0) {
            extendBoundsForPath(childZone.path);
          }
        });
      }

      mapRef.current.fitBounds(bounds);
      const center = {
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng(),
      };
      setMapCenter(center);
      setCenteredPolygonCenter(center);
    },
    [mapRef],
  );

  // Dibujo los polygons, teniendo en cuenta si es zona o agrupadora, con sus markers correspondientes
  const renderPolygons = useCallback(() => {
    if (!polygons.length) return null;

    return polygons.flatMap((zone: Zone) => {
      const paths = [
        zone.path,
        ...((zone.childrenZones as Zone[])?.map((c: Zone) => c.path) || []),
      ]
        .filter(Boolean)
        .map((p) => (p ? p.map(convertPointToLatLngLiteral) : []));

      return paths.map((path, _id) => (
        <React.Fragment key={`${zone.id}-${_id}`}>
          <Polygon
            paths={path}
            options={{
              ...defaultPolygonOptions,
              strokeColor: getColorForPolygon(zone.id!),
              fillColor: getColorForPolygon(zone.id!),
            }}
            onLoad={(polygon) => {
              polygonRefs.current[zone.id!] = polygon;
            }}
            onUnmount={() => delete polygonRefs.current[zone.id!]}
          />
          {markersVisible && (
            <Marker
              position={getPolygonCenter(path)}
              options={{ optimized: true }}
              label={{
                className: classes.marker,
                text: zone.fullName?.toUpperCase() ?? '',
              }}
              icon={{ url: '', scaledSize: new google.maps.Size(0, 0) }}
            />
          )}
        </React.Fragment>
      ));
    });
  }, [polygons, getColorForPolygon, markersVisible, classes.marker]);

  // Dibujo la zona pasada como parametro como editable.
  const addEditablePolygon = useCallback(
    (polygon: Zone) => {
      if (!mapRef.current || !drawingManagerRef.current || !polygon.path)
        return;
      const newPolygon = new google.maps.Polygon({
        paths: polygon.path.map(convertPointToLatLngLiteral),
        strokeColor: getColorForPolygon(polygon.id!),
        fillColor: getColorForPolygon(polygon.id!),
        editable: true,
        ...defaultPolygonOptions,
      });
      const newMarker = new google.maps.Marker({
        position: getPolygonCenter(
          newPolygon
            .getPath()
            .getArray()
            .map(
              (latLng: LatLng): LatLngLiteral => ({
                lat: latLng.lat(),
                lng: latLng.lng(),
              }),
            ),
        ),
        optimized: true,
        label: {
          className: classes.marker,
          text: polygon.fullName?.toUpperCase() ?? '',
        },
        icon: {
          url: '',
          scaledSize: new google.maps.Size(0, 0),
        },
      });

      setPolygonListeners(newPolygon);
      adjustMapBoundsForZone(polygon);
      newPolygon.setMap(mapRef.current);
      newMarker.setMap(mapRef.current);
    },
    [
      setPolygonListeners,
      getColorForPolygon,
      adjustMapBoundsForZone,
      mapRef,
      drawingManagerRef,
    ],
  );

  // Reseteo el poligono editable a su estado original, o borro el poligono si es recien creado.
  const resetPolygon = useCallback(() => {
    if (!drawingManagerRef.current) return;
    if (currentPolygon) {
      currentPolygon.setMap(null);
      setCurrentPolygon(null);
      if (onPolygonChange) onPolygonChange([]);
      drawingManagerRef.current.setDrawingMode(
        google.maps.drawing.OverlayType.POLYGON,
      );
      if (editablePolygon) {
        addEditablePolygon(editablePolygon);
      }
    }
  }, [drawingManagerRef, currentPolygon, onPolygonChange, addEditablePolygon]);

  if (editablePolygon && isAddZone) {
    console.error('Trying both to edit a zone and create one.');
    return null;
  }

  // Muestro un placeholder si no estan cargadas las zonas
  if (arePolygonsLoading)
    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.7)', // semi-transparent background
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin tip="Cargando mapa..." size="large" />
        </div>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={mapCenter}
          zoom={zoom}
        />
      </div>
    );

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={mapCenter}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onMouseUp={() => {
        if (mapRef.current) setMapCenter(mapRef.current.getCenter()!.toJSON());
      }}
    >
      {polygons && renderPolygons()}
      {(isAddZone || !!editablePolygon) && (
        <Button
          size="large"
          style={{
            position: 'absolute',
            bottom: '20px',
            left: '10px',
            zIndex: 1,
            padding: '0 20px',
          }}
          onClick={resetPolygon}
        >
          Resetear polígono
        </Button>
      )}
      {centerPolygon && centeredPolygonCenter != mapCenter && (
        <Button
          size="large"
          style={{
            position: 'absolute',
            bottom: '20px',
            left: '10px',
            zIndex: 1,
            padding: '0 20px',
          }}
          onClick={() => adjustMapBoundsForZone(centerPolygon)}
        >
          Volver a centrar
        </Button>
      )}
    </GoogleMap>
  );
};

export default React.memo(Map);
