/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Modal, theme } from 'antd';
import PaqueryTable from '@paquery-team/lib-table';
import PropTypes from 'prop-types';
import { ContainerOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { createUseStyles } from 'react-jss';

import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import SITELINKS from 'constants/sitelinks';
import { TableDateTimeFormat } from 'constants/dateFormats';
import {
  useMarketplaceOptions,
  useOnTransitStatusOptions,
} from 'hooks/useOptions';
import externalCodeColumn from 'components/tables/columns';
import ViewPackageButton from 'components/viewPackagebutton';
import {
  marketplacesObjectItemSelector,
  marketplacesItemsSelector,
} from 'redux/marketplaces/selectors';
import { selectGlobals } from 'redux/globals/selectors';
import {
  loadedSelector,
  itemsSelector,
  pageableSelector,
} from 'redux/packagesMassive/selectors';
import { actions } from 'redux/packagesMassive/slice';
import useMassiveViewPackages from 'redux/packagesMassive';
import SizeIndicator from 'components/sizeIndicator';
import EditRecordButton from 'components/editRecordButton';

const useStyles = createUseStyles((_theme) => {
  return {
    antModalContent: {
      padding: 0,
    },
    icon: {
      color: _theme.colorPrimary,
      fontSize: 18,
    },
  };
});

const useColumns = () => {
  const destinationPersonNameColumn = {
    title: 'Nombre destinatario',
    dataIndex: 'destinationPersonName',
  };

  const destinationAddressColumn = {
    title: 'Destino',
    dataIndex: 'destinationAddress',
  };

  const deliveryTermColumn = {
    title: 'Plazo',
    dataIndex: 'deliveryTerm',
    width: 130,
  };

  const arrivedAtPaqueryPointDateColumn = {
    title: 'Arribó a PaqueryPoint',
    dataIndex: 'arrivedAtPaqueryPointDate',
    align: 'center',
  };

  const statusColumn = {
    title: 'Estado',
    dataIndex: 'statusDescription',
  };

  const sizeColumn = {
    title: 'Tamaño',
    dataIndex: 'packageSize',
    align: 'center',
    render: (text) => <SizeIndicator text={text[0]} />,
    sorter: true,
    sortDirections: ['descend'],
  };

  const editColumn = {
    align: 'center',
    render: (_, record) => (
      <EditRecordButton
        record={record}
        link={`${SITELINKS.packages.list}/id`}
      />
    ),
  };
  const viewPackageColumn = {
    align: 'center',
    render: (_, packet) => <ViewPackageButton packageId={packet.id} />,
  };

  const dataColumns = [
    externalCodeColumn,
    destinationPersonNameColumn,
    destinationAddressColumn,
    deliveryTermColumn,
    sizeColumn,
    arrivedAtPaqueryPointDateColumn,
    statusColumn,
  ];
  return {
    dataColumns,
    columnsLargeDevice: [...dataColumns, viewPackageColumn, editColumn],
    columnsSmallDevice: [externalCodeColumn, viewPackageColumn, editColumn],
    columnsMediumDevice: [
      externalCodeColumn,
      statusColumn,
      viewPackageColumn,
      editColumn,
    ],
  };
};

const useDatasource = () => {
  const globals = useSelector(selectGlobals);
  const items = useSelector(itemsSelector);
  const pageable = useSelector(pageableSelector);
  const marketplacesSource = useSelector(marketplacesObjectItemSelector);
  const [datasource, setDatasource] = useState([]);
  useEffect(() => {
    const marketplaces = Object.values(marketplacesSource);
    setDatasource(
      items.map((packet) => ({
        ...packet,
        key: packet.code,
        destinationPersonName: packet.shippingScheduleDestination.name,
        destinationAddress:
          packet.shippingScheduleDestination.shippingAddress.addressDetail,
        deliveryTerm: globals.deliveryTerm.find(
          (term) => term.value === packet.deliveryTerm,
        ).description,
        zone: packet.shippingScheduleDestination.zone?.name,
        marketplace:
          marketplaces.find((market) => packet.ownerID === market.id)?.name ??
          'De otro OPL',
        packageSize: globals.packages.size.find(
          (packSize) => packSize.value === packet.packageSize,
        ).name,
        statusDescription: globals.packages.status.find(
          (state) => state.value === packet.status,
        ).name,
        arrivedAtPaqueryPointDate: packet.arrivedAtPaqueryPointDate
          ? dayjs(packet.arrivedAtPaqueryPointDate)
              .tz()
              .format(TableDateTimeFormat)
          : null,
      })),
    );
  }, [items, marketplacesSource]);
  return { datasource, pageable };
};

const PackagesList = ({ code }) => {
  const { token } = theme.useToken();
  const [visible, setVisible] = useState(false);
  useMassiveViewPackages();
  const globals = useSelector(selectGlobals);

  const dispatch = useDispatch();
  const classes = useStyles({ theme: token });

  const {
    dataColumns,
    columnsLargeDevice,
    columnsSmallDevice,
    columnsMediumDevice,
  } = useColumns();

  const marketplaces = useSelector(marketplacesItemsSelector);
  const marketplacesOptions = useMarketplaceOptions(marketplaces);

  const loaded = useSelector(loadedSelector);
  const { datasource, pageable } = useDatasource();
  const filteredOnTransitStatuses = useOnTransitStatusOptions();

  useEffect(() => {
    if (visible) {
      dispatch(actions.updateCode(code));
    }
  }, [visible]);

  const searchCallback = useCallback(
    (value) => {
      dispatch(actions.updateSearch(value));
    },
    [dispatch],
  );

  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePageable(page));
    },
    [dispatch],
  );

  const updateDate = useCallback(
    (value) => {
      dispatch(actions.updateDate(value));
    },
    [dispatch],
  );

  const selectCallback = useCallback(
    (value) => {
      dispatch(actions.updateStatus(value));
    },
    [dispatch],
  );
  const selectMarketplaceCallback = useCallback(
    (marketSelected) => {
      dispatch(actions.updateMarket(marketSelected));
    },
    [dispatch],
  );
  const selectPackageTypeCallback = useCallback(
    (newPackageType) => {
      dispatch(actions.updatePackageType(newPackageType));
    },
    [dispatch],
  );

  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Código o destinatario',
    allowEmptySearch: true,
  };
  const selectors = [
    {
      onChange: selectMarketplaceCallback,
      placeholder: 'Marketplace',
      list: marketplacesOptions,
    },
    {
      onChange: selectPackageTypeCallback,
      placeholder: 'Tipo de envio',
      list: globals.packages.type,
    },
    {
      onChange: selectCallback,
      placeholder: 'Estado',
      list: filteredOnTransitStatuses,
    },
  ];
  const rangePicker = {
    onDateSelection: updateDate,
    required: {
      value: true,
      message: 'La fecha es requerida',
    },
  };

  return (
    <>
      <ContainerOutlined
        onClick={() => setVisible(true)}
        className={classes.icon}
      />
      <Modal
        width="80vw"
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        closable={false}
        className={classes.antModalContent}
        open={visible}
      >
        <PaqueryTable
          loading={!loaded}
          header={{
            title: code,
            selectors,
            searcher,
            rangePicker,
            refresh: () => dispatch(actions.refreshPage()),
          }}
          onChangePaginate={updatePaginate}
          dataSource={datasource}
          paginate={pageable || DEFAULT_PAGINATE}
          dataColumns={dataColumns}
          colsForSmallDevice={columnsSmallDevice}
          colsForMediumDevice={columnsMediumDevice}
          colsForLargeDevice={columnsLargeDevice}
          usePackageRowColors
        />
      </Modal>
    </>
  );
};

PackagesList.propTypes = {
  code: PropTypes.string.isRequired,
};

export default PackagesList;
