import React, { useEffect, useState, useCallback } from 'react';
import JsDownload from 'js-file-download';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobals } from 'redux/globals/selectors';
import {
  packagesPageableSelector,
  packagesDateSelector,
  packagesLoadedSelector,
  packagesStatusSelector,
  packagesSearchSelector,
  packagesTypeSelector,
  packagesItemsSelector,
  packagesMarketFilterSelector,
  packagesStoreSelector,
  packagesTermSelector,
} from 'redux/packages/selectors';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import usePackages from 'redux/packages';
import useMarketplaces from 'redux/marketplaces';
import { actions } from 'redux/packages/slice';
import { actions as paquerActions } from 'redux/paquers/slice';
import { storeItemsSelector } from 'redux/store/selectors';
import useStore from 'redux/store';
import { actions as storeActions } from 'redux/store/slice';
import dayjs from 'dayjs';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import { readyForDeliveryStatus } from 'constants/packages';
import API from 'constants/api';
import SITELINKS from 'constants/sitelinks';
import { TableDateTimeFormat, ExportDateFormat } from 'constants/dateFormats';
import { notification } from 'antd';
import rest from 'util/Api';
import PaqueryTable from '@paquery-team/lib-table';
import ViewPackageButton from 'components/viewPackagebutton';
import PaquerSettingsButton from 'components/paquerSettingsButton';
import DownloadLabelButton from 'components/downloadLabelButton';
import {
  useMarketplaceOptions,
  useOnTransitStatusOptions,
  useStoreOptions,
} from 'hooks/useOptions';
import externalCodeColumn from 'components/tables/columns';
import usePaquers from 'redux/paquers';
import SizeIndicator from 'components/sizeIndicator';
import EditRecordButton from 'components/editRecordButton';

const downloadLabelColumn = {
  render: (_, record) => (
    <DownloadLabelButton
      packageId={record.key}
      externalCode={record.externalCode}
    />
  ),
};

const destinationPersonNameColumn = {
  title: 'Nombre destinatario',
  dataIndex: 'destinationPersonName',
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTermDescription',
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'statusDescription',
};

const sizeColumn = {
  title: 'Tamaño',
  dataIndex: 'packageSizeDescription',
  align: 'center',
  render: (text) => <SizeIndicator text={text[0]} />,
};

const editColumn = {
  align: 'center',
  render: (_, record) => (
    <EditRecordButton record={record} link={`${SITELINKS.packages.list}/id`} />
  ),
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packageId={record.id} />,
};

const paquerSettingsColumn = {
  align: 'center',
  render: (_, record) => (
    <PaquerSettingsButton packageId={record} actions={actions} />
  ),
};

const dataColumns = [
  externalCodeColumn,
  destinationPersonNameColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  sizeColumn,
  arrivedAtPaqueryPointDateColumn,
  statusColumn,
];

const actionColumns = [paquerSettingsColumn, viewPackageColumn, editColumn];

const columnsLargeDevice = [
  downloadLabelColumn,
  ...dataColumns,
  ...actionColumns,
];

const columnsMediumDevice = [
  downloadLabelColumn,
  externalCodeColumn,
  statusColumn,
  ...actionColumns,
];

const columnsSmallDevice = [
  downloadLabelColumn,
  externalCodeColumn,
  ...actionColumns,
];

const PackageList = () => {
  usePackages();
  useMarketplaces({ initialize: true });
  useStore();
  usePaquers();
  const dispatch = useDispatch();
  const globals = useSelector(selectGlobals);
  const marketplaces = useSelector(marketplacesItemsSelector);
  const marketplacesOptions = useMarketplaceOptions(marketplaces);
  const storeFilter = useSelector(packagesStoreSelector);
  const deliveryTerm = useSelector(packagesTermSelector);
  const stores = useSelector(storeItemsSelector);
  const storesOptions = useStoreOptions(stores);
  const search = useSelector(packagesSearchSelector);
  const status = useSelector(packagesStatusSelector);
  const packageType = useSelector(packagesTypeSelector);
  const date = useSelector(packagesDateSelector);
  const marketFilter = useSelector(packagesMarketFilterSelector);
  const loaded = useSelector(packagesLoadedSelector);
  const items = useSelector(packagesItemsSelector);
  const pageable = useSelector(packagesPageableSelector);
  const [disabled, setDisabled] = useState(false);
  const [selectedPackagesId, setSelectedPackagesId] = useState([]);
  const filteredOnTransitStatuses = useOnTransitStatusOptions();

  useEffect(() => {
    dispatch(paquerActions.initial());
  }, [dispatch]);

  useEffect(() => {}, [dispatch]);

  const handleExportCsv = async () => {
    setDisabled(true);
    try {
      const searchParams = new URLSearchParams({
        search,
        status,
        marketplaceId: marketFilter || '',
        storeId: storeFilter || '',
        packageType,
        deliveryTerm,
        from: date.from,
        to: date.to,
      });
      // eslint-disable-next-line import/no-named-as-default-member
      const response = await rest.get(
        `${API.reports.packages}?${searchParams.toString()}`,
        {
          responseType: 'blob',
        },
      );
      const filename = `Paquetes-${dayjs().tz().format(ExportDateFormat)}.xls`;
      JsDownload(response.data, filename);
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.log(exception);
      notification.error({
        message: 'Ha ocurrido un error al traer los paquetes',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };

  const rowSelection = {
    type: 'checkbox',
    columnWidth: '30px',
    hideDefaultSelections: true,
    selectedRowKeys: selectedPackagesId,
    onChange: (selectedRowKeys) => {
      setSelectedPackagesId(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: !record.isDeliverable,
      name: record.externalCode,
    }),
  };

  const searchCallback = useCallback(
    (value) => {
      dispatch(actions.updateSearch(value));
    },
    [dispatch],
  );

  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePageable(page));
    },
    [dispatch],
  );

  const updateDate = useCallback(
    (value) => {
      dispatch(actions.updateDate(value));
    },
    [dispatch],
  );

  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Código o destinatario',
    allowEmptySearch: true,
  };
  const selectCallback = useCallback(
    (value) => {
      dispatch(actions.updateStatus(value));
    },
    [dispatch],
  );
  const selectMarketplaceCallback = useCallback(
    (marketSelected) => {
      dispatch(actions.updateMarket(marketSelected));
      if (marketSelected) dispatch(storeActions.all(marketSelected));
    },
    [dispatch],
  );
  const selectStoreCallback = useCallback(
    (storeSelected) => {
      dispatch(actions.updateStore(storeSelected));
    },
    [dispatch],
  );
  const selectPackageTypeCallback = useCallback(
    (newPackageType) => {
      dispatch(actions.updatePackageType(newPackageType));
    },
    [dispatch],
  );
  const selectDeliveryTermCallback = useCallback(
    (newDeliveryTerm) => {
      dispatch(actions.updateDeliveryTerm(newDeliveryTerm));
    },
    [dispatch],
  );
  const selectors = [
    {
      onChange: selectMarketplaceCallback,
      placeholder: 'Marketplace',
      list: marketplacesOptions,
    },
    {
      onChange: selectStoreCallback,
      placeholder: 'Tienda',
      list: storesOptions,
    },
    {
      onChange: selectPackageTypeCallback,
      placeholder: 'Tipo de envio',
      list: globals.packages.type,
    },
    {
      onChange: selectCallback,
      placeholder: 'Estado',
      list: filteredOnTransitStatuses,
    },
    {
      onChange: selectDeliveryTermCallback,
      placeholder: 'Plazo',
      list: globals.deliveryTerm,
    },
  ];

  const rangePicker = {
    onDateSelection: updateDate,
    required: {
      value: true,
      message: 'La fecha es requerida',
    },
  };

  let filteredPackages = [];
  if (loaded && items) {
    filteredPackages = items.map((packet) => ({
      ...packet,
      key: packet.id,
      externalCode: packet.externalCode,
      destinationPersonName: packet.shippingScheduleDestination.name,
      destinationAddress:
        packet.shippingScheduleDestination.shippingAddress.addressDetail,
      deliveryTermDescription:
        packet.deliveryTerm &&
        globals.deliveryTerm.find((term) => term.value === packet.deliveryTerm)
          .description,
      packageSizeDescription: globals.packages.size.find(
        (packSize) => packSize.value === packet.packageSize,
      ).name,
      arrivedAtPaqueryPointDate: packet.arrivedAtPaqueryPointDate
        ? dayjs(packet.arrivedAtPaqueryPointDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      statusDescription: globals.packages.status.find(
        (state) => state.value === packet.status,
      ).name,
      isDeliverable: readyForDeliveryStatus.some((id) => id === packet.status),
    }));
  }
  return (
    <>
      <PaqueryTable
        rowSelection={rowSelection}
        loading={!loaded}
        header={{
          title: 'En transito',
          selectors,
          searcher,
          rangePicker,
          onExportCsv: { callback: handleExportCsv, disabled },
          refresh: () => dispatch(actions.refreshPage()),
        }}
        onChangePaginate={updatePaginate}
        dataSource={filteredPackages}
        paginate={pageable || DEFAULT_PAGINATE}
        dataColumns={dataColumns}
        colsForSmallDevice={columnsSmallDevice}
        colsForMediumDevice={columnsMediumDevice}
        colsForLargeDevice={columnsLargeDevice}
        usePackageRowColors
      />
    </>
  );
};

export default PackageList;
