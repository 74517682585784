import React, { useEffect, useState } from 'react';
import { Table, theme } from 'antd';
import PropTypes from 'prop-types';
import { ContainerOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import {
  marketplacesLoadedSelector,
  marketplacesObjectItemSelector,
} from 'redux/marketplaces/selectors';
import SITELINKS from 'constants/sitelinks';
import { TableDateTimeFormat } from 'constants/dateFormats';
import ViewPackageButton from 'components/viewPackagebutton';
import { selectGlobals } from 'redux/globals/selectors';
import dayjs from 'dayjs';
import { itemsSelector, loadedSelector } from 'redux/dispatch/selectors';
import useDispatchView from 'redux/dispatch';
import SizeIndicator from 'components/sizeIndicator';
import EditRecordButton from 'components/editRecordButton';
import Modal from 'components/modal';

const useColumns = () => {
  const externalCodeColumn = {
    title: 'Codigo Externo',
    dataIndex: 'externalCode',
  };

  const deliveryTermColumn = {
    title: 'Plazo',
    dataIndex: 'deliveryTerm',
    width: 130,
  };

  const packageTypeColumn = {
    title: 'Tipo',
    dataIndex: 'packageType',
  };

  const zoneColumn = {
    title: 'Zona',
    dataIndex: 'zone',
    align: 'center',
  };

  const scheduledDateColumn = {
    title: 'Fecha Programada',
    dataIndex: 'scheduledDate',
    align: 'center',
  };

  const marketplaceColumn = {
    title: 'Marketplace',
    dataIndex: 'marketplace',
  };

  const sizeColumn = {
    title: 'Tamaño',
    dataIndex: 'packageSize',
    align: 'center',
    render: (text) => <SizeIndicator text={text[0]} />,
    sorter: true,
    sortDirections: ['descend'],
  };

  const editColumn = {
    align: 'center',
    render: (_, record) => (
      <EditRecordButton
        record={record}
        link={`${SITELINKS.packages.list}/id`}
      />
    ),
  };
  const viewPackageColumn = {
    align: 'center',
    render: (_, packet) => <ViewPackageButton packageId={packet.id} />,
  };
  return [
    externalCodeColumn,
    deliveryTermColumn,
    scheduledDateColumn,
    zoneColumn,
    packageTypeColumn,
    marketplaceColumn,
    sizeColumn,
    viewPackageColumn,
    editColumn,
  ];
};

const useDatasource = (loaded) => {
  // TODO: seguir haciendo redux de esta pagina con el fetch usando endpoint
  const globals = useSelector(selectGlobals);
  const items = useSelector(itemsSelector);
  const marketplacesSource = useSelector(marketplacesObjectItemSelector);
  const [datasource, setDatasource] = useState([]);
  useEffect(() => {
    if (loaded) {
      const marketplaces = Object.values(marketplacesSource);
      setDatasource(
        items.map((packet) => ({
          ...packet,
          deliveryTerm: globals.deliveryTerm.find(
            (term) => term.value === packet.deliveryTerm,
          ).description,
          packageType: globals.packages.type.find(
            (packType) => packType.value === packet.packageType,
          ).name,
          zone: packet.shippingScheduleDestination.zone?.name,
          scheduledDate: packet.shippingScheduleDestination.scheduledDate
            ? dayjs(packet.shippingScheduleDestination.scheduledDate)
                .tz()
                .format(TableDateTimeFormat)
            : null,
          marketplace:
            marketplaces.find((market) => packet.ownerID === market.id)?.name ??
            'De otro OPL',
          packageSize: globals.packages.size.find(
            (packSize) => packSize.value === packet.packageSize,
          ).name,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, marketplacesSource]);
  return datasource;
};

const PackagesList = ({ departure }) => {
  const [visible, setVisible] = useState(false);
  useDispatchView({ initialize: visible, departure });
  const marketplacesLoaded = useSelector(marketplacesLoadedSelector);
  const packagesLoaded = useSelector(loadedSelector);
  const loaded = packagesLoaded && marketplacesLoaded;
  const datasource = useDatasource(loaded);
  const columns = useColumns();
  const { token } = theme.useToken();
  return (
    <>
      <ContainerOutlined
        onClick={() => setVisible(true)}
        style={{ color: token.colorPrimary, fontSize: 18 }}
      />
      <Modal
        title="Listado de paquetes"
        width="80vw"
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        closable={false}
        footer={null}
        open={visible}
      >
        <Table loading={!loaded} dataSource={datasource} columns={columns} />
      </Modal>
    </>
  );
};

PackagesList.propTypes = {
  departure: PropTypes.shape().isRequired,
};

export default PackagesList;
