/* eslint-disable import/no-named-as-default-member */
import rest, { getData } from 'util/Api';
import { PREFIX_REGEX } from './defaultValues';
import API from './api';

export const haveDateLoaded = (date) => date.from !== '' && date.to !== '';

export const getCaronteBasicAuth = () => {
  const caronteUsername = process.env.REACT_APP_CARONTE_USERNAME;
  const carontePassword = process.env.REACT_APP_CARONTE_PASSWORD;
  if (!caronteUsername || caronteUsername === '') {
    const error = new Error('El usuario caronte no esta configurado');
    // eslint-disable-next-line
    console.log(error.message);
    throw error;
  }
  if (!carontePassword || carontePassword === '') {
    const error = new Error('La contraseña caronte no esta configurada');
    // eslint-disable-next-line
    console.log(error.message);
    throw error;
  }
  const authBuffer = Buffer.from(`${caronteUsername}:${carontePassword}`);
  const authBase64 = authBuffer.toString('base64');
  return `Basic ${authBase64}`;
};

export const getPrefix = (phone) => {
  if (!phone) {
    return null;
  }
  const regExp = PREFIX_REGEX;
  const prefix = phone.match(regExp);
  return prefix ? prefix[0] : null;
};

export const removePrefix = (phone) => {
  if (!phone) {
    return '';
  }
  const regExp = PREFIX_REGEX;
  const prefix = phone.match(regExp);
  if (prefix) {
    const phoneWithoutPrefix = phone.replace(prefix[0], '').trim();
    return phoneWithoutPrefix.replace(/[()]/g, '').trim();
  }
  return phone;
};

export const fetchPackage = async (externalCode) => {
  try {
    if (externalCode === null || externalCode === '') {
      const error = new Error('El codigo externo no puede ir vacío.');
      return error;
    }
    const searchParams = new URLSearchParams({
      page: 0,
      take: 1000,
      size: 1000,
      externalCode,
    });
    const packet = await rest.get(
      `${API.packages.getByExternalCode}?${searchParams.toString()}`,
    );
    if (packet.data.content.length === 0) {
      const error = new Error(
        'El paquete con el codigo externo indicado no existe',
      );
      return error;
    }
    return packet.data.content;
  } catch (error) {
    return error;
  }
};

export const fetchPackageWithExactExtCode = async (externalCode) => {
  try {
    if (externalCode === null || externalCode === '') {
      const error = new Error('El codigo externo no puede ir vacío.');
      return error;
    }
    const searchParams = new URLSearchParams({
      page: 0,
      size: 1000,
      externalCode,
    });
    const packet = await rest.get(
      `${
        API.packages.getByExternalCodeFromArrivedOrDispatch
      }?${searchParams.toString()}`,
    );
    if (packet.data.content.length === 0) {
      const error = new Error(
        'El paquete con el codigo externo indicado no existe',
      );
      return error;
    }
    return packet.data.content;
  } catch (error) {
    return error;
  }
};

export const fetchPackageById = async (id) => {
  try {
    if (id === null || id === '') {
      const error = new Error('El codigo externo no puede ir vacío.');
      return error;
    }
    const packet = await getData(`${API.packages.getById}${id}`);
    return packet;
  } catch (error) {
    if (error.message.includes(404)) {
      return new Error('No se ha encontrado el paquete con el id solicitado');
    }
    return error;
  }
};

export const fetchPaquer = async (id) => {
  try {
    if (id === null || id === '') {
      const error = new Error('El id del paquer no puede ir vacío.');
      return error;
    }
    const paquer = await rest.get(`${API.paquer.getById}${id}`);
    if (!paquer.data.data) {
      const error = new Error(
        'El paquete contiene un paquer cuyo id indicado no existe',
      );
      return error;
    }
    return paquer.data.data;
  } catch (error) {
    return error;
  }
};

export const getZoneFromAddress = async (address, oplId) => {
  const options = {
    headers: {
      authorization: getCaronteBasicAuth(),
    },
  };
  const zoneRequest = await rest.apiAxios.post(
    API.zones.resolve,
    { address, oplId },
    options,
  );

  if (!zoneRequest?.data?.data) return { id: null, name: null };
  const { resolvedAddress, zone: responseZone } = zoneRequest.data.data;

  return {
    id: responseZone.legacy_id,
    name: responseZone.name,
    postalCode: resolvedAddress.postalCode,
    resolutedAddress: resolvedAddress.adress,
    resolutedStreet: resolvedAddress.street,
    resolutedStreetNumber: resolvedAddress.streetNumber,
    resolutedLocality: resolvedAddress.locality,
    lat: resolvedAddress.lat,
    lng: resolvedAddress.lng,
  };
};

export const getCurrentPosition = (options = {}) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
};

export const getCoordsFromPolygonGeoJson = (geoJson) => {
  if (!geoJson)
    throw new Error('La zona leida no tiene parametros de geojson requeridos.');
  if (!geoJson.geometry)
    throw new Error(
      'El geojson de la zona recibida le esta faltando la propiedad geometria',
    );
  if (geoJson.geometry.type !== 'Polygon')
    throw new Error(
      'La zona requerida no tiene el tipo de geojson requerido para poligono.',
    );
  if (!geoJson.geometry.coordinates)
    throw new Error('La zona recibida no tiene coordenadas cargadas');
  return geoJson.geometry.coordinates[0].map((coord) => ({
    lat: coord[1],
    lng: coord[0],
  }));
};

export default {};
